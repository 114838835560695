import { Background, WrapperComponentProps } from "constants/types";
import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { FC, Fragment, useState } from "react";
import { getEdgeSettings } from "components/wrapper/components";
import { Im } from "types";
import { Lightbox } from "components/lightbox";
import { Row } from "react-bootstrap";
import { TipTapComponent } from "components/wrapper/components/text";
import classNames from "classnames";
import HeadingWrapper from "../heading";
import { getSpeakingId } from "helpers/text-processing";

const getCaption = (imageText: ImageText): string[] => {
  if (!imageText?.overwrite_caption) {
    return [];
  }

  return imageText.caption.split("\n");
};

interface ImageText {
  ar?: string;
  background?: Background;
  caption?: string;
  images?: Im[];
  overwrite_caption?: string[];
  show_image_caption?: boolean;
  show_lightbox?: boolean;
  text?: string;
}

interface ImageLightboxProps {
  image: Im;
  image_text: ImageText;
}

interface ImageTextWrapperProps extends WrapperComponentProps {
  image_position?:
    | "right"
    | "right-top"
    | "left-top"
    | "left"
    | "top"
    | "bottom";
  image_text: ImageText | ImageText[];
  image_width?: number;
  quote_layout?: boolean;
}

const ImageTextWrapper: FC<ImageTextWrapperProps> = (props) => {
  const [open, setOpen] = useState(false);
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  const captions = !Array.isArray(props.image_text)
    ? getCaption(props.image_text)
    : props.image_text.reduce(
        (captions, imageText) => [...captions, ...getCaption(imageText)],
        [],
      );

  const ImageLightbox = ({ image, image_text }: ImageLightboxProps) => (
    <Lightbox
      heading={{
        title: image?.title,
        stylingLevel: "h4",
      }}
      headingType="primary"
      background="grey-triangle"
      size="large"
      open={open}
      setOpen={setOpen}
    >
      <Cloudinary media={image} background={image_text.background} />
    </Lightbox>
  );

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={
        props.custom_id || anchorNavId || `component__image-text-${props.index}`
      }
      key={`component__image-text-${props.index}`}
      className="section component__image-text styled-list-wrapper quotes "
    >
      <HeadingWrapper {...props} />

      <>
        {Array.isArray(props.image_text) &&
          props.image_text?.map((image_text, index) => {
            return props.quote_layout ? (
              <div
                key={index}
                className={
                  "quote mt-4" +
                  (props.image_position === "right" ? " flex-row-reverse" : "")
                }
                {...(props.slug && { id: props.slug })}
              >
                <div
                  {...(image_text.show_lightbox && {
                    onClick: () => {
                      setOpen(true);
                    },
                  })}
                  className={image_text.show_lightbox ? `hover__zoom` : null}
                >
                  {image_text.images?.map((image, index) => (
                    <Fragment key={`image-text-wrapper-image-${index}`}>
                      <Cloudinary
                        media={image}
                        width={200}
                        height={200}
                        ar="ar11"
                        className={
                          props.image_position === "right"
                            ? "mr-md-0 ml-md-4"
                            : null
                        }
                      />
                      {image_text.show_lightbox ? (
                        <ImageLightbox image={image} image_text={image_text} />
                      ) : null}
                    </Fragment>
                  ))}
                </div>
                <figure className="text-left">
                  <blockquote className="blockquote">
                    <TipTapComponent tree={image_text.text} />
                  </blockquote>
                </figure>
              </div>
            ) : (
              <Row
                key={`image-text-wrapper-${index}`}
                {...(props.slug && { id: props.slug })}
                style={{
                  flexDirection:
                    props.image_position === "right" ||
                    props.image_position === "right-top"
                      ? "row-reverse"
                      : props.image_position === "top"
                      ? "column"
                      : props.image_position === "bottom"
                      ? "column-reverse"
                      : "row",
                }}
              >
                <div
                  className={classNames(
                    "d-flex mt-2",
                    `align-items-${
                      props.image_position === "left-top" ||
                      props.image_position === "right-top"
                        ? "start"
                        : "center"
                    }`,

                    `col-md${
                      props.image_position === "top" ||
                      props.image_position === "bottom"
                        ? "-" + props.image_width
                        : ""
                    }`,
                    props.image_position === "top" && "mb-1",
                    props.image_position === "bottom" && "mt-1",
                    image_text.show_lightbox && "hover__zoom",
                  )}
                  style={props.image_width === 8 ? { flexGrow: 2 } : null}
                  {...(image_text.show_lightbox && {
                    onClick: () => {
                      setOpen(true);
                    },
                  })}
                >
                  {image_text.images?.map((image, index) => (
                    <Fragment key={`image-text-wrapper-image-${index}`}>
                      <Cloudinary
                        media={image}
                        ar={image_text.ar}
                        background={image_text.background}
                        caption={
                          image_text.show_image_caption
                            ? captions[index] ?? image.caption
                            : undefined
                        }
                      />
                      {image_text.show_lightbox ? (
                        <ImageLightbox image={image} image_text={image_text} />
                      ) : null}
                    </Fragment>
                  ))}
                </div>
                <div
                  className="d-flex flex-column align-items-start justify-content-center col-md"
                  style={
                    +props.image_width === 4
                      ? { flexGrow: 2 }
                      : +props.image_width === 3
                      ? { flexGrow: 3 }
                      : +props.image_width === 2
                      ? { flexGrow: 5 }
                      : null
                  }
                >
                  <TipTapComponent tree={image_text.text} />
                </div>
              </Row>
            );
          })}
      </>
    </Container>
  );
};

export default ImageTextWrapper;
